import Typography from '@ui/Typography';
import { t } from '@lib/i18n';
import { SmallPostCard, useSitePosts } from '@entities/post';
import Slider from '@ui/Slider';
import { SliderCustomButton } from '@ui/SliderCustomButton';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.cases.${key}`);
const items = tr('items');

export const Cases = () => {
  const isMobile = useMediaQuery('(max-width: 687px)');
  const posts = useSitePosts().all.filter((post) => [post.category, ...post.tags].includes('case')).slice(0, 9);
  
  return (
    <section>
      <div className="grid-layout mx-auto my-8">
        <BlockCaption>{tr('caption')}</BlockCaption>
        <Typography variant="header2" className="mt-4 mb-7 text-center">{tr('title')}</Typography>

        <Slider
          settings={{
            slidesToShow: 4,
            infinite: true,
            arrows: !isMobile,
            nextArrow: <SliderCustomButton addClassName="!pr-[8px] !pl-[12px] md:!pr-[5px] md:!pl-[7px] !right-[-60px]" />,
            prevArrow: <SliderCustomButton addClassName="!pr-[12px] !pl-[8px] md:!pl-[5px] md:!pr-[7px] !left-[-60px]" />,
            responsive: [
              {
                breakpoint: 1365,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                },
              },
            ],
            dots: isMobile,
          }}
          className="add-top-padding"
          gap={4}
        >
          {posts.map((post) => (
            <SmallPostCard key={post.id} post={post} />
          ))}
        </Slider>
      </div>
    </section>
  );
};
