import { useState } from 'react';
import StaticIcon from '@ui/StaticIcon';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Slider from '@ui/Slider';
import ArrowIcon from '../../../../../static/images/arrow-right.svg';

import { BlockCaption } from '../BlockCaption';
import DiscussProblemBlock from '../DiscussProblemBlock';

const tr = (key) => t(`main_page.functional.${key}`);
const cards = tr('cards');

const arrow_colors = {
  pink400: 'stroke-pink400',
  purple400: 'stroke-purple400',
  yellow400: 'stroke-yellow400',
  cyan400: 'stroke-cyan400',
  teal400: 'stroke-teal400',
};

export const Functional = () => {
  const isScrollableTab = useMediaQuery('(max-width: 1024px)');

  const [selectedIndex, setSelectedIndex] = useState(0);
  console.log(selectedIndex);
  
  return (
    <section id="features">
      <div className="grid-layout mx-auto mt-8">
        <BlockCaption>{tr('caption')}</BlockCaption>
        <Typography variant="header2" className="mt-4 text-center">{tr('title')}</Typography>

        <div className="mt-7">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            {!isScrollableTab ? (
              <Tab.List className="mx-auto flex w-fit rounded-2xl border border-tertiary300 bg-tertiary200 p-1">
                {cards.map((card, index) => (
                  <Tab as="div" className="flex items-center outline-0" key={card.icon}>
                    {({ selected }) => (
                      <>
                        {!!index && <div className={clsx('h-3 w-[1px] bg-tertiary500', (index === selectedIndex || index === selectedIndex + 1) && '!bg-transparent')} />}
                        <button type="button" className={clsx('group  relative bg-transparent px-2 py-1 outline-0', selected && 'rounded-lg !bg-white shadow-[0px_0px_40px_0px_#C8D6FF8F]')}>
                          <Typography weight="medium" color="secondary100" className={clsx(selected && 'text-secondary400', 'group-hover:text-secondary400')}>
                            {card.title}
                          </Typography>
                          <StaticIcon folder="main-page" name={card.icon} className={clsx('absolute -top-2 -right-2 rotate-[8deg] opacity-0 transition-all duration-300', selected && '!opacity-100')} />
                        </button>
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            ) : (
              <Tab.List className="mx-auto flex rounded-2xl border border-tertiary300 bg-tertiary200 p-1">
                <Slider
                  settings={{
                    dots: false, variableWidth: true, swipeToSlide: true,
                  }}
                  className="tab-slider w-full"
                >
                  {cards.map((card, index) => (
                    <Tab as="div" className="flex items-center outline-0 active:outline-0" key={card.icon}>
                      {({ selected }) => (
                        <>
                          {/* {!!index && <div className={clsx('h-3 w-[1px] bg-tertiary500', (index === selectedIndex || index === selectedIndex + 1) && '!bg-transparent')} />} */}
                          <button type="button" className={clsx('group w-max bg-transparent px-2 py-1 outline-none active:outline-0', selected && 'rounded-lg !bg-white')}>
                            <Typography weight="medium" color="secondary100" className={clsx(selected && 'text-secondary400', 'group-hover:text-secondary400')}>
                              {card.title}
                            </Typography>
                          </button>
                        </>
                      )}
                    </Tab>
                  ))}
                </Slider>
              </Tab.List>
            )}

            <Tab.Panels className="mt-7">
              {cards.map((card, index) => (
                <Tab.Panel
                  key={card.icon}
                >
                  <div className="grid grid-cols-12 gap-2 lg-down:hidden">
                    {card.items.map((item, i) => {
                      return (
                        <div key={item.title} className={clsx('h-[340px] rounded-[32px] border border-tertiary300 bg-cover bg-center p-4', item.imageBg, item.col)}>
                          <Typography variant="header5" className="text-center">{item.title}</Typography>
                        </div>
                      );
                    })}
                  </div>

                  <Slider
                    className="hidden text-center lg-down:block"
                    gap={2}
                  >
                    {card.items.map((item, i) => {
                      return (
                        <div key={item.title} className={clsx('h-[340px] max-w-[400px] rounded-[32px] border border-tertiary300 bg-cover bg-center p-4', item.imageBg)}>
                          <Typography variant="header5" className="text-center">{item.title}</Typography>
                        </div>
                      );
                    })}
                  </Slider>

                  <a href={card.link} target="_blank" rel="noopener noreferrer" className="group mt-7 mb-8 flex items-end justify-center gap-0.5">
                    <Typography color={card.link_color} className="text-2lg">{card.link_text}</Typography>
                    <ArrowIcon className={clsx('group-hover:animate-[slide_500ms]', arrow_colors[card.link_color])} />
                  </a>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>

        <DiscussProblemBlock />
      </div>
    </section>
  );
};
