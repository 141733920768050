import clsx from 'clsx';

import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import StaticIcon from '@ui/StaticIcon';
import Slider from '@ui/Slider';
import { BlockCaption } from '../BlockCaption';

import './Industries.scss';

const Wrapper = ({
  link, children, className, key,
}) => {
  if (link) {
    return <a href={link} target="_blank" className={className} key={key}>{children}</a>;
  }

  return <div className={className} key={key}>{children}</div>;
};

const IndustryCard = ({ item }) => {
  const {
    title, body, color, icon, link,
  } = item;

  return (
    <Wrapper 
      link={link}
      className="industries__card group relative flex items-center rounded-2xl border-[1px] border-solid border-tertiary300 bg-white md:rounded-xl md:shadow-[6px_6px_40px_#ECF2FF;]"
    >
      <div className={clsx(
        color,
        'md-up:border-r-solid flex h-full items-center px-4 sm:py-3 sm:px-2.5 md:px-3.5 md:py-4 md-up:border-r-[1px] md-up:border-r-tertiary300',
        'rounded-l-2xl sm:border-r-0 md:rounded-l-xl',
        'transition delay-300 ease-in-out',
      )}
      >
        <StaticIcon 
          name={icon}
          className="h-6 w-6"
        />
      </div>
      <div className={clsx(
        'flex h-[164px] w-full flex-col justify-center sm:h-[100px] md:h-[116px] lg:justify-start',
        'rounded-r-2xl sm:pr-3 sm:text-start md:rounded-r-xl md-up:pl-3 md-up:pr-4 lg:pt-3',
      )}
      >
        <Typography className="font-semibold lg:mb-1">
          {title}
        </Typography>
        <Typography className="hidden text-xs !text-secondary200 lg:block">
          {body}
        </Typography>
      </div>

      {link && (
        <div className="absolute top-1.5 right-1.5 block rounded-lg border border-tertiary300 bg-tertiary200 p-[2px]">
          <StaticIcon name="arrow-up" className="h-[20px] w-[20px]" />
        </div>
      )}
    </Wrapper>
  );
};

export default function Industries({ t }) {
  const cards = t('industries.cards');
  const mobileCards = [cards.slice(0, 3), cards.slice(3, 6), cards.slice(6)];
  
  return (
    <section
      id="industries" 
      className={clsx(
        'mx-1 lg:mx-3 lg-down:scroll-mt-18',
        'rounded-b-[32px] border border-t-0 border-transparent border-b-tertiary400 bg-border-gradient',
      )}
    >
      <div className={clsx(
        'px-5 py-8 sm:px-2 sm:pb-7 sm:pt-8 md:px-[28px]',
        'rounded-b-[32px] bg-[linear-gradient(180deg,rgba(243,247,255,0)0%,#F3F7FF_100%)]',
      )}
      >
        <BlockCaption>
          {t('industries.caption')}
        </BlockCaption>
        <Typography
          variant="header2"
          align="center"
          className="mt-4 sm:mt-3"
        >
          {t('industries.title')}
        </Typography>

        <MediaQuery greaterThan="sm">
          <div
            className={clsx(
              'mx-auto max-w-[1312px] gap-3 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 lg:gap-4 xl:grid-cols-3',
              'mt-7',
            )}
          >
            {cards.map((item) => (
              <IndustryCard item={item} key={item.icon} />
            ))}
          </div>
        </MediaQuery>
        <MediaQuery at="sm">
          <div className="mt-5">
            <Slider gap={2}>
              {mobileCards.map((cardGroup, index) => (
                <div className="flex flex-col space-y-3" key={index}>
                  {cardGroup.map((card) => (
                    <IndustryCard item={card} key={card.icon} />
                  ))}
                </div>
              ))}
            </Slider>
          </div>
        </MediaQuery>
      </div>
    </section>
  );
}
