import { RequestFeedback } from '@features/request-feedback';
import { t } from '@lib/i18n';
import { bem } from '@lib/bem';
import MediaQuery from '@ui/MediaQuery';

import Typography from '@ui/Typography';

import './DiscussProblemBlock.scss';

const tr = (key) => t(`main_page.discuss_problem.${key}`);

const {
  block,
  element,
} = bem('discuss-problem-block');

const BlockContent = () => {
  return (
    <div {...element('card', {}, 'shadow-[6px_6px_40px_#ECF2FF] border border-tertiary300 rounded-[32px]')}>
      <div className="md:col-8 md:col-offset-start-1 sm:mx-auto sm:px-3 sm:py-7 md:py-8 lg:px-10 lg:py-8">
        <div className="row middle-lg flex-column-less-than-lg sm:text-left md:text-center">
          <div className="flex-column">
            <Typography variant="header3" className="[&>span]:bg-[linear-gradient(90.84deg,#3878FF_0%,#121E2D_18.27%)] [&>span]:bg-clip-text [&>span]:text-transparent">
              {tr('header')}
            </Typography>
            <Typography variant="subhead1" {...element('discuss-problem-subhead', {}, 'mt-2-lg mt-3-less-than-lg lg-down:max-w-[640px]')}>
              {tr('subheader')}
            </Typography>
          </div>
          <RequestFeedback.Consultation.Button className="ml-auto-lg mt-4-less-than-lg z-10 md:mx-auto md:self-start">
            {tr('button')}
          </RequestFeedback.Consultation.Button>
        </div>
      </div>
    </div>
  );
};

const DiscussProblemBlock = () => {
  return (
    <div {...block({}, '')}>
      <MediaQuery at="sm">
        <div className="mx-3">
          <BlockContent />
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className="mx-auto max-w-[1312px] xl-down:mx-4.5">
          <BlockContent />
        </div>
      </MediaQuery>
    </div>
  );
};

export default DiscussProblemBlock;
