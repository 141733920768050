import clsx from 'clsx';
import Typography from '@ui/Typography';
import { t } from '@lib/i18n';
import Slider from '@ui/Slider';
import { SliderCustomButton } from '@ui/SliderCustomButton';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Image from '@ui/Image';
import RibbonIcon from '../../../../../static/images/main-page/ribbon.svg';

import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.rewards.${key}`);
const items = tr('items');

const ribbonColor = {
  1: 'fill-[#FFD700]',
  2: 'fill-[#C0C0C0]',
};

const Card = ({ item }) => {  
  return (
    <a href={item.link} target="_blank" rel="noopener noreferrer" className="group">
      <div className={clsx(
        'relative flex h-[162px] flex-col justify-between rounded-2xl border border-tertiary300 p-3',
        item.link && 'group-hover:shadow-[6px_6px_40px_0px_#ECF2FF]',
      )}
      >
        <RibbonIcon className={clsx(
          'absolute right-3 top-0 fill-[#FFD700]',
          item.place === 1 && ribbonColor[1],
          item.place === 2 && ribbonColor[2],
        )}
        />
        {item.place && <Image src={`/images/main-page/place-${item.place}.webp`} className="absolute top-[19px] right-3.5 h-[27px] w-4" />}
        <div className="mb-0.5 w-min border-b border-tertiary300">
          <Typography color="primary400" className="text-2xs font-medium">{item.year}</Typography>
        </div>
        <Typography variant="header5">{item.award}</Typography>
      </div>
      <Typography className="mt-2 text-sm">{item.category}</Typography>
      <Typography className="mt-1.5 text-2xs !text-secondary200">{item.person}</Typography>
    </a>
  );
};

export const Rewards = () => {
  const isMobile = useMediaQuery('(max-width: 687px)');

  return (
    <section className="mx-3 bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] py-8">
      <div className="grid-layout mx-auto">
        <BlockCaption>{tr('caption')}</BlockCaption>
        <Typography variant="header2" className="mt-4 text-center">{tr('title')}</Typography>
      </div>

      <div className="grid-layout mx-auto mt-7">
        <Slider
          settings={{
            slidesToShow: 4,
            arrows: !isMobile,
            nextArrow: <SliderCustomButton addClassName="!pr-[8px] !pl-[12px] md:!pr-[5px] md:!pl-[7px] !right-[-60px]" />,
            prevArrow: <SliderCustomButton addClassName="!pr-[12px] !pl-[8px] md:!pl-[5px] md:!pr-[7px] !left-[-60px]" />,
            responsive: [
              {
                breakpoint: 1365,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                },
              },
            ],
            dots: isMobile,
          }}
          gap={4}
        >
          {items.map((item, index) => (
            <Card item={item} key={index} />
          ))}
        </Slider>
      </div>
    </section>
  );
};
